import React from 'react';
import { VehicleClass } from '../data/api';
import useSlug from '../utils/use-slug';
import { OperatorName } from './OperatorLogo';
import { Select, SelectItem } from './Select';
import 'core-js/es/object/from-entries';
import {
    PerCityTimePeriodsByVehicleClass,
    TimePeriodsByVehicleClass,
    TimeRangeListItem,
} from '../data/available-time-ranges';
import { flatten, uniq, uniqBy } from 'lodash-es';
import { CitiesIndexInfo } from '../data/city-info';

export const VEHICLE_CLASSES: Record<VehicleClass, SelectItem> = {
    all: { key: 'all', name: 'All Micromobility' },
    bike: { key: 'bike', name: 'Bikes', icon: 'Bike' },
    car: { key: 'car', name: 'Cars', icon: 'Car' },
    'e-bike': { key: 'e-bike', name: 'E-Bikes', icon: 'eBike' },
    moped: { key: 'moped', name: 'Mopeds', icon: 'Moped' },
    scooter: { key: 'scooter', name: 'Scooters', icon: 'Scooter' },
    'seated-scooter': {
        key: 'seated-scooter',
        name: 'Seated Scooters',
        icon: 'SeatedScooter',
    },
    'delivery-robot': {
        key: 'delivery-robot',
        name: 'Delivery Robots',
        icon: 'Bike',
    },
    'cargo-bike': { key: 'cargo-bike', name: 'Cargo Bikes', icon: 'CargoBike' },
    'cargo-e-bike': {
        key: 'cargo-e-bike',
        name: 'Cargo E-Bikes',
        icon: 'CargoeBike',
    },
    bus: { key: 'bus', name: 'Buses', icon: 'Bus' },
    truck: { key: 'truck', name: 'Trucks', icon: 'Truck' },
    motorcycle: { key: 'motorcycle', name: 'Motorcycles', icon: 'Moped' },
};

interface DataSelectorProps {
    timePeriodsAvailableByVehicleClass: TimePeriodsByVehicleClass;
    timeRange: string;
    setTimeRange: (timeRange: string) => void;
    vehicleClass: string;
    setVehicleClass: (vehicleClass: string) => void;
}

export default function DataSelector({
    timePeriodsAvailableByVehicleClass,
    timeRange,
    setTimeRange,
    vehicleClass,
    setVehicleClass,
}: DataSelectorProps) {
    const slug = useSlug();

    const availableVehicleClasses = Object.keys(
        timePeriodsAvailableByVehicleClass
    );

    const availableTimeRanges: TimeRangeListItem[] =
        timePeriodsAvailableByVehicleClass[vehicleClass];

    const timeRangeItems = Object.fromEntries(
        availableTimeRanges.map(({ name, key }) => [key, { name, key }])
    );

    const vehicleClassItems = Object.fromEntries(
        availableVehicleClasses.map(vc => [
            vc,
            {
                ...(VEHICLE_CLASSES[vc] as SelectItem),
            },
        ])
    );

    // for now we override the portland dashboard to include custom branding
    // if we do this for more dashboards, we should move this to a backend setting
    if (slug === 'pdx' && vehicleClassItems['e-bike']) {
        vehicleClassItems['e-bike'].subtitle = {
            operatorLogo: 'Biketown' as OperatorName,
            color: '#FC4C02',
            blackText: false,
        };
        vehicleClassItems['e-bike'].name = 'Bikes / E-Bikes';
    }

    return (
        <BaseDataSelector
            vehicleClassItems={vehicleClassItems}
            setVehicleClass={setVehicleClass}
            vehicleClass={vehicleClass}
            timeRangeItems={timeRangeItems}
            setTimeRange={setTimeRange}
            timeRange={timeRange}
        />
    );
}

export interface BaseDataSelectorProps {
    vehicleClassItems: Record<string, SelectItem>;
    setVehicleClass: (string) => void;
    vehicleClass: string;
    timeRangeItems: Record<string, SelectItem>;
    setTimeRange: (string) => void;
    timeRange: string;
}

export function BaseDataSelector({
    vehicleClassItems,
    setVehicleClass,
    vehicleClass,
    timeRangeItems,
    setTimeRange,
    timeRange,
}: BaseDataSelectorProps) {
    return (
        <>
            <Select
                dropdownSide="left"
                items={vehicleClassItems}
                onChange={setVehicleClass}
                selectedItemKey={vehicleClass}
            />
            <Select
                dropdownSide="left"
                icon="Calendar"
                items={timeRangeItems}
                onChange={setTimeRange}
                selectedItemKey={timeRange}
            />
        </>
    );
}
