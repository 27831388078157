import React from 'react';
import icons from '../assets/icons.svg';
import cx from 'classnames';

import styles from '../styles/Icon.module.scss';

/** A string enum of all the icons that exist. */
export type IconName =
    /* Navigation & Direction */
    | 'Audit'
    | 'Back'
    | 'Breadcrumb'
    | 'Check'
    | 'Close'
    | 'Collapse'
    | 'Double-Left'
    | 'Double-Right'
    | 'Down'
    | 'Down-Arrow'
    | 'Expand'
    | 'Fees'
    | 'Forward'
    | 'Hamburger'
    | 'Heatmap'
    | 'Higher'
    | 'Inbound'
    | 'Left'
    | 'Lower'
    | 'Map'
    | 'Minus'
    | 'More'
    | 'Navigation-Chart'
    | 'Orientation'
    | 'Outbound'
    | 'Plus'
    | 'Points'
    | 'Policy'
    | 'Refresh'
    | 'Report'
    | 'Right'
    | 'Satellite'
    | 'Street'
    | 'Trip-End'
    | 'Trip-Start'
    | 'Up'
    | 'Up-Arrow'
    /* Action */
    | 'Calendar'
    | 'Camera'
    | 'Collapse'
    | 'Copy-Paste'
    | 'Day'
    | 'Delete'
    | 'Download'
    | 'Drawing'
    | 'Email'
    | 'Expand'
    | 'Image'
    | 'Layer'
    | 'Month'
    | 'Plus'
    | 'Pointer'
    | 'Print'
    | 'SelectArrows'
    | 'Search'
    | 'Upload'
    | 'Visibility-Off'
    | 'Visibility-On'
    | 'Week'
    | 'Filter'
    | 'Reset-Filters'
    /* Social */
    | 'Facebook'
    | 'LinkedIn'
    | 'Twitter'
    /* Content */
    | 'Alert'
    | 'AllVehicles'
    | 'Archive'
    | 'Battery-0-33'
    | 'Battery-33-66'
    | 'Battery-66-100'
    | 'Battery-Disabled'
    | 'Bike'
    | 'Car'
    | 'Circle'
    | 'Cloudy'
    | 'Code'
    | 'Comment'
    | 'Compliant'
    | 'Document-Agreement'
    | 'Document-Privacy'
    | 'eBike'
    | 'General'
    | 'Geography'
    | 'Information'
    | 'List-File'
    | 'Location'
    | 'Marker'
    | 'Missing-File'
    | 'Moped'
    | 'Non-Compliant'
    | 'Rainy'
    | 'Response'
    | 'Scooter'
    | 'SeatedScooter'
    | 'Bus'
    | 'Truck'
    | 'CargoBike'
    | 'CargoeBike'
    | 'Spreadsheet'
    | 'Status'
    | 'Stopwatch'
    | 'Stormy'
    | 'Sunny'
    | 'Time'
    | 'Triangle'
    | 'User-Remove'
    /* Map */
    /* Localization */
    | 'Australia'
    | 'European-Union'
    | 'France'
    | 'Germany'
    | 'New-Zealand'
    | 'Spain'
    | 'United-Kingdom'
    | 'United-States';

interface IconProps {
    className?: string;
    icon: IconName;
    style?: React.CSSProperties;
}

const HALF_ICONS: IconName[] = [
    'Battery-0-33',
    'Battery-33-66',
    'Battery-66-100',
    'Battery-Disabled',
];

/**
 * An Icon component that renders an svg of the chosen icon. Its size is
 * determined by the parent's font size, and the color by the font color.
 */
const Icon = ({ icon, style, className = '' }: IconProps) => {
    return (
        <svg
            className={cx(styles.icon, className, {
                half: HALF_ICONS.includes(icon),
            })}
            style={style}
        >
            <use xlinkHref={`${icons}#icon-${icon}`} />
        </svg>
    );
};

export default Icon;
