import React from 'react';
import styles from '../styles/ErrorBoundary.module.scss';

interface Props {
    children: React.ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
    state: State = {
        hasError: false,
    };

    static getDerivedStateFromError(error: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.log(error, errorInfo);

        if (window.rollbar) {
            window.rollbar.error(error, errorInfo);
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <h1 className={styles.errorMessage}>Something went wrong.</h1>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
