import cx from 'classnames';
import Head from 'next/head';

import styles from '../styles/Layout.module.scss';
import ErrorBoundary from './ErrorBoundary';
import { DateTime } from 'luxon';

interface LayoutProps {
    children: React.ReactNode;
    header: React.ReactNode;
    title: string;
}

/** Our default layout, used for all pages. */
export default function Layout({ children, title, header }: LayoutProps) {
    const favicon =
        process.env.NEXT_PUBLIC_DASHBOARD_ENVIRONMENT === 'production'
            ? '/logo.png'
            : '/logo-staging.png';

    return (
        <div className={styles.container}>
            <Head>
                <title>{title}</title>
                <link rel="icon" href={favicon} />
                <meta
                    name="description"
                    content="The Ride Report Micromobility Dashboard provides aggregated and anonymized data on e-scooter and bike share programs. With Ride Report cities and people can better understand micromobility in their communities."
                />

                {/* open graph data */}
                <meta
                    property="og:description"
                    content="The Ride Report Micromobility Dashboard provides aggregated and anonymized data on e-scooter and bike share programs. With Ride Report cities and people can better understand micromobility in their communities."
                />
                <meta
                    property="og:url"
                    content="https://public.ridereport.com"
                />
                <meta property="og:title" content={title} />
                <meta
                    property="og:image"
                    content="https://public.ridereport.com/og-image.jpg"
                />

                {/* twitter data */}
                <meta name="twitter:title" content={title} />
                <meta
                    name="twitter:description"
                    content="The Ride Report Micromobility Dashboard provides aggregated and anonymized data on e-scooter and bike share programs. With Ride Report cities and people can better understand micromobility in their communities."
                />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@ridereport" />
                <meta
                    name="twitter:image"
                    content="https://public.ridereport.com/og-image.jpg"
                />
            </Head>

            {header}

            <main className={styles.main}>
                <ErrorBoundary>{children}</ErrorBoundary>
            </main>

            <footer className={styles.footer}>
                <div>
                    <div className={styles.cta}>
                        <img
                            alt="Ride Report"
                            src="/logo-icon.svg"
                            className={styles.ctaLogo}
                        />
                        <h2 className={styles.subheader}>About Ride Report</h2>
                        <p>
                            We’re in the business of transportation change. Our
                            mission is to make micromobility data more
                            sophisticated and accessible than automobile data to
                            help drive, deliver, and defend the adoption of
                            bikes and scooters.
                        </p>
                        <div className={styles.ctaButtonContainer}>
                            <a
                                className={cx('button', 'green')}
                                href="https://www.ridereport.com/contact"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Get in touch
                            </a>
                        </div>
                    </div>
                </div>
                <div className={styles.footerLinks}>
                    <a
                        target="_blank"
                        href="https://www.ridereport.com/"
                        className={cx('link', styles.footerLink)}
                        rel="noreferrer"
                    >
                        Home
                    </a>
                    <a
                        target="_blank"
                        href="https://www.ridereport.com/press"
                        className={cx('link', styles.footerLink)}
                        rel="noreferrer"
                    >
                        Press
                    </a>
                    <a
                        target="_blank"
                        href="https://www.ridereport.com/jobs"
                        className={cx('link', styles.footerLink)}
                        rel="noreferrer"
                    >
                        {/* uncomment to add notification above careers <div data-descr /> */}
                        Careers
                    </a>
                    <a
                        target="_blank"
                        href="https://www.ridereport.com/blog"
                        className={cx('link', styles.footerLink)}
                        rel="noreferrer"
                    >
                        Blog
                    </a>
                    <a
                        target="_blank"
                        href="https://www.ridereport.com/contact"
                        className={cx('link', styles.footerLink)}
                        rel="noreferrer"
                    >
                        Contact
                    </a>
                </div>
                <div className={styles.footerLogoContainer}>
                    <img
                        alt="Ride Report"
                        src="/logo.svg"
                        className={styles.footerLogo}
                    />
                    <span className={styles.verticalBar} />
                    Micromobility Dashboard
                </div>
                © Ride Report 2021-{DateTime.now().get('year')}. All rights
                reserved.
            </footer>
        </div>
    );
}
