import React, { useState } from 'react';
import cx from 'classnames';
import Icon, { IconName } from '../components/Icon';
import styles from '../styles/Select.module.scss';
import OperatorLogo, { OperatorName } from './OperatorLogo';

export interface SelectItem {
    key: string;
    name: string;
    icon?: IconName;
    subtitle?: {
        blackText: boolean;
        color: string;
        operatorLogo: OperatorName;
    };
}

interface SelectProps<T> {
    dropdownSide?: 'left' | 'right';
    icon?: IconName;
    items: Record<string, SelectItem>;
    onChange: (key: string) => void;
    selectedItemKey: string;
    buttonLabelFunction?: (selectedItemName: string) => string;
    additionalClassForDropdownContainer?: string | null;
    additionalClassForDropdownOpen?: string | null;
}

export const Select = <T extends object>({
    dropdownSide = 'right',
    icon,
    items,
    onChange,
    selectedItemKey,
    buttonLabelFunction,
    // Allow overriding default styling by accepting optional unique classnames referencing the stylesheet of the parent component
    additionalClassForDropdownContainer,
    additionalClassForDropdownOpen,
}: SelectProps<T>) => {
    const [isDropdownActive, setIsDropdownActive] = useState(false);

    const selectedItem = items[selectedItemKey] || { name: '-', key: '-' };
    const hasItems = Object.keys(items).length > 0;
    const mainIcon = icon || selectedItem.icon || undefined;

    return (
        <div
            className={cx(
                styles.dropdownContainer,
                additionalClassForDropdownContainer
            )}
        >
            {isDropdownActive ? (
                <div
                    className={styles.dropdownOuter}
                    onClick={() => {
                        setIsDropdownActive(false);
                    }}
                />
            ) : null}
            <button
                disabled={!hasItems}
                type="button"
                className={styles.dropdownButton}
                onClick={() => setIsDropdownActive(!isDropdownActive)}
            >
                {mainIcon && <Icon className={styles.icon} icon={mainIcon} />}

                <div className={styles.itemDescription}>
                    {buttonLabelFunction && (
                        <span>{buttonLabelFunction(selectedItem.name)}</span>
                    )}
                    {!buttonLabelFunction && <span>{selectedItem.name}</span>}
                    {selectedItem.subtitle && (
                        <span
                            className={styles.subtitle}
                            style={{
                                color: selectedItem.subtitle.blackText
                                    ? 'black'
                                    : 'white',
                                backgroundColor: selectedItem.subtitle.color,
                            }}
                        >
                            <OperatorLogo
                                operator={selectedItem.subtitle.operatorLogo}
                            />
                        </span>
                    )}
                </div>
                {hasItems && (
                    <Icon
                        className={styles.icon}
                        icon={isDropdownActive ? 'Up' : 'Down'}
                    />
                )}
            </button>
            {hasItems && (
                <div
                    className={cx(
                        'shadow-8',
                        styles.dropdown,
                        isDropdownActive && styles.showDropdown,
                        dropdownSide === 'right' ? 'right' : 'left',
                        additionalClassForDropdownOpen
                    )}
                >
                    <div className={styles.items}>
                        {Object.entries(items).map(([key, item]) => (
                            <div
                                key={key}
                                className={cx(
                                    styles.item,
                                    key === selectedItemKey &&
                                        styles.itemActive,
                                    item.icon && styles.itemWithIcon
                                )}
                                onClick={() => {
                                    onChange(item.key);
                                    setIsDropdownActive(false);
                                }}
                            >
                                <div className={styles.iconNameContainer}>
                                    {item.icon && (
                                        <Icon
                                            className={styles.itemIcon}
                                            icon={item.icon}
                                        />
                                    )}
                                    <div className={styles.itemDescription}>
                                        <span>{item.name}</span>
                                        {item.subtitle && (
                                            <span
                                                className={styles.subtitle}
                                                style={{
                                                    color: item.subtitle
                                                        .blackText
                                                        ? 'black'
                                                        : 'white',
                                                    backgroundColor:
                                                        item.subtitle.color,
                                                }}
                                            >
                                                <OperatorLogo
                                                    operator={
                                                        item.subtitle
                                                            .operatorLogo
                                                    }
                                                />
                                            </span>
                                        )}
                                    </div>
                                </div>
                                {item.key === selectedItemKey && (
                                    <Icon icon="Check" />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
