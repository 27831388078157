import { Dispatch, SetStateAction } from 'react';
import cx from 'classnames';

import styles from '../styles/ColumnHeader.module.scss';
import Icon from './Icon';
import useTippy from '../utils/use-tippy';

interface ColumnHeaderProps<T extends string> {
    setSorting: Dispatch<
        SetStateAction<readonly [column: T, order: 'asc' | 'desc']>
    >;
    column: T;
    sorting: readonly [column: T, order: 'asc' | 'desc'];
    text: string;
    secondaryText?: string;
    tooltipText?: string;
}

function ColumnHeader<T extends string>({
    setSorting,
    column,
    sorting,
    text,
    secondaryText,
    tooltipText,
}: ColumnHeaderProps<T>) {
    const [sortColumn, sortOrder] = sorting;
    const [headerRef] = useTippy<HTMLDivElement>(
        tooltipText ? tooltipText : null,
        { placement: 'bottom' }
    );
    return (
        <th
            className={cx(styles.columnHeader, {
                showArrow: sortColumn === column,
            })}
        >
            <button
                onClick={() =>
                    setSorting(([sortColumn, sortOrder]) => [
                        column,
                        sortColumn !== column
                            ? column === 'name' // the name column works opposite the numeric columns
                                ? 'asc'
                                : 'desc'
                            : sortOrder === 'desc'
                            ? 'asc'
                            : 'desc',
                    ])
                }
            >
                <div ref={headerRef} className={styles.columnHeaderText}>
                    <span>{text}</span>
                    {secondaryText && <span>{secondaryText}</span>}
                </div>
                {
                    <Icon
                        icon={
                            sortColumn !== column ||
                            // invert the arrow for the name column
                            (sortOrder === 'desc') != (column === 'name')
                                ? 'Down-Arrow'
                                : 'Up-Arrow'
                        }
                    />
                }
            </button>
        </th>
    );
}

export default ColumnHeader;
