import { useRef, useEffect } from 'react';
import tippy, { Props, roundArrow, Instance as TippyInstance } from 'tippy.js';
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/animations/shift-away.css';
import useMemoizedObject from './use-memoized-object';

// Take the available tippy options, and remove the ones that we set manually
// for you inside of the hook.
type TippyOptions = Partial<
    Omit<
        Props,
        | 'content'
        | 'arrow'
        | 'boundary'
        | 'delay'
        | 'popperOptions'
        | 'distance'
        | 'theme'
        | 'animation'
    >
>;

tippy.setDefaultProps({
    offset: [0, 80],
});

/**
 * Render a tooltip with the given string when the user hovers over an element.
 *
 * @example
```tsx
const tip = useTippy("Hello world", { placement: "bottom" });
return (<div {...tip}>Hello world</div>);
```
 */
const useTippy = <T extends HTMLElement>(
    content: string | null,
    options: TippyOptions
) => {
    const show = !!content;
    const memoizedOptions = useMemoizedObject(options);
    const elementToAttachTo = useRef<T>(null);
    const tooltip = useRef<TippyInstance | null>(null);
    useEffect(() => {
        if (show && elementToAttachTo.current) {
            const tip = tippy(elementToAttachTo.current, {
                content: '',
                arrow: roundArrow,
                offset: [0, 12],
                theme: 'ride-report-dark',
                animation: 'shift-away',
                delay: [100, 100],
                ...memoizedOptions,
            });
            tooltip.current = tip;
            return () => {
                tooltip.current = null;
                tip.destroy();
            };
        }
    }, [show, memoizedOptions]);
    useEffect(() => {
        if (tooltip.current) {
            tooltip.current.setContent(content ?? '');
        }
    });
    return [elementToAttachTo, tooltip.current] as const;
};

export default useTippy;
