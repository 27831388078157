import { useRef } from 'react';
import isEqual from 'lodash/isEqual';

/**
 * Memoize an object with deep equality comparison, so that its reference will only change if one
 * of the properties changes, without worrying about if the input has the same reference or not.
 */
const useMemoizedObject = <T>(obj: T): T => {
    const ref = useRef(obj);
    if (!isEqual(ref.current, obj)) {
        ref.current = obj;
    }
    return ref.current;
};

export default useMemoizedObject;
