import useTippy from '../utils/use-tippy';
import Icon from './Icon';
import styles from '../styles/LinearUnitSelector.module.scss';
import { useLinearUnitsInfo } from '../utils/use-linear-units';

const LinearUnitSelector = () => {
    let linearUnits = useLinearUnitsInfo();
    const [selectorRef] = useTippy<HTMLDivElement>(
        linearUnits.systemName === 'imperial'
            ? 'Switch to metric units'
            : 'Switch to imperial units',
        { placement: 'bottom' }
    );

    return (
        <div className={styles.linearUnitSelector}>
            <strong className={styles.label}>Unit:</strong>
            <span
                className={styles.selectorSpan}
                ref={selectorRef}
                onClick={() =>
                    linearUnits.toggleLinearUnits(linearUnits.systemName)
                }
            >
                {linearUnits.units}
                <span className={styles.iconSpan}>
                    <Icon icon="SelectArrows" />
                </span>
            </span>
        </div>
    );
};

export default LinearUnitSelector;
