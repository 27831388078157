const NUMBER_FORMATTERS = {
    integer: new Intl.NumberFormat(undefined, {
        maximumFractionDigits: 0,
    }),
    float: new Intl.NumberFormat(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }),
};

export default NUMBER_FORMATTERS;
