import { ChartDataType } from '../components/metrics/MetricChart';
import { MetricsData } from '../components/metrics/use-metrics';
import { LinearUnitsInfo } from './use-linear-units';

function convertKilometersToMiles(value: number): number {
    return value / 1.609344;
}

function convertMileToKilometers(value: number): number {
    return value * 1.609344;
}

// For individual metrics, convert the data value if needed based on native and user-selected linear units
export function convertLinearUnitsForDisplay(
    value: number,
    nativeUnitsString: string,
    linearUnitsForDisplay: LinearUnitsInfo
): number {
    // Handle intial hydration with placeholder units
    if (linearUnitsForDisplay.systemName === 'placeholder') {
        // Return 0 until the proper units are set
        return 0;
    }
    if (nativeUnitsString === linearUnitsForDisplay.units) {
        return value;
    }
    if (
        nativeUnitsString === 'miles' &&
        linearUnitsForDisplay.units === 'kilometers'
    ) {
        return convertMileToKilometers(value);
    }
    if (
        nativeUnitsString === 'kilometers' &&
        linearUnitsForDisplay.units === 'miles'
    ) {
        return convertKilometersToMiles(value);
    } else {
        throw new Error(
            `Unsupported units (${nativeUnitsString}) provided for a metric`
        );
    }
}

export function convertChartDataForDisplay(
    chartType: ChartDataType,
    metrics: MetricsData[],
    linearUnitsForDisplay: LinearUnitsInfo
): MetricsData[] {
    // Only converting distance values
    if (chartType !== 'tripDistancePerDay') {
        return metrics;
    }
    const metricsUnits = metrics[0][chartType].yUnit; // Distance is currently only displayed on y-axis
    // Check if conversion is needed
    if (metricsUnits !== linearUnitsForDisplay.units) {
        // Copy the array of objects to avoid downstream consequences
        const convertedMetrics: MetricsData[] = metrics.map(metric =>
            JSON.parse(JSON.stringify(metric))
        ) as [MetricsData];
        // Now modify the copy
        convertedMetrics.forEach(cm => {
            cm[chartType].dataBuckets.forEach(
                db =>
                    (db.yValue = convertLinearUnitsForDisplay(
                        db.yValue,
                        metricsUnits,
                        linearUnitsForDisplay
                    ))
            );
            cm[chartType].yUnit = linearUnitsForDisplay.units;
        });
        return convertedMetrics;
    }
    return metrics;
}
