import { RoutesCountData, RoutesData } from '../components/map/use-routes';
import {
    HighInjuryNetworkData,
    StreetSegmentsData,
} from '../components/map/use-street-segments';
import { MetricsData } from '../components/metrics/use-metrics';
import { PublicDashSummaryImport } from './public-dash-data';

const API_HOST = process.env.NEXT_PUBLIC_API_HOST;
const PUBLIC_API_HOST = `${API_HOST}/public`;
const IMPACT_API_HOST = `${API_HOST}/impact`;
const PUBLIC_S3_HOST =
    'https://public-data-exports-production.s3-us-west-2.amazonaws.com';
const IMPACT_S3_HOST =
    'https://impact-data-exports-production.s3-us-west-2.amazonaws.com';
const PUBLIC_SUMMARY_PRODUCTION = 'public_dashboard_summary.json';
const PUBLIC_SUMMARY_STAGING = 'public_dashboard_$t@ging_summary.json';

export interface Rule {
    id: string;
    name: string;
    rule_type: string;
    geographies: [string];
    days: string[];
    minimum: number;
    maximum: number;
    start_time: string;
    end_time: string;
}

export interface Policy {
    name: string;
    description: string;
    units: string;
    rules: Rule[];
}

export interface Settings {
    apply_external_trip_counts: boolean;
    currently_active_operators: string[];
    earliest_available_metrics: string;
    earliest_available_routes: string; // 2019-04-25
    initial_view_bounds: [number, number, number, number];
    max_bounds: [number, number, number, number];
    measurement_system: 'imperial' | 'metric';
    name: string;
    organization_address_line_one: string;
    organization_address_line_two: string;
    organization_contact_email: string;
    organization_display_name: string;
    organization_logo_url: string;
    organization_url: string;
    policies: Policy[];
    region_location_name: string;
    region_title: string;
    region_dashboards: [{ name: string; slug: string }];
    population: number;
    tags: string[];
    mapview_slug: string;
}

async function settings(slug: string): Promise<Settings | null> {
    const searchParams = new URLSearchParams({ slug: slug });

    if (process.env.NEXT_PUBLIC_DASHBOARD_ENVIRONMENT) {
        searchParams.append(
            'environment',
            process.env.NEXT_PUBLIC_DASHBOARD_ENVIRONMENT
        );
    }

    const url = `${PUBLIC_API_HOST}/settings?${searchParams}`;
    const res = await fetch(url);

    if (!res.ok) {
        if (res.status === 404) {
            return null;
        }

        throw new Error(
            `Could not fetch settings: ${res.status} ${res.statusText}`
        );
    }

    const data = await res.json();
    // override pdx settings to use biketown instead of lyft
    if (slug === 'pdx') {
        data.currently_active_operators = data.currently_active_operators.map(
            name => (name === 'Lyft' ? 'Biketown' : name)
        );
    }
    // Add mapview slug to the Settings object
    data.mapview_slug = slug;
    // ensure region dashboards are sorted by name
    data.region_dashboards = data.region_dashboards.sort((a, b) =>
        a.name.localeCompare(b.name)
    );

    return data as Settings;
}

export interface DashboardSlug {
    name: string;
    slug: string;
}

async function allDashboardSlugs() {
    const environmentQueryParam = process.env.NEXT_PUBLIC_DASHBOARD_ENVIRONMENT
        ? `?environment=${process.env.NEXT_PUBLIC_DASHBOARD_ENVIRONMENT}`
        : '';
    const res = await fetch(
        `${PUBLIC_API_HOST}/all_settings${environmentQueryParam}`
    );

    if (!res.ok) {
        throw new Error(
            `Could not fetch all dashboards: ${res.status} ${res.statusText}`
        );
    }

    const data = await res.json();
    return data as DashboardSlug[];
}

async function isDataAvailableForDatePeriod(
    slug: string,
    vehicleClass: VehicleClass,
    /** Formatted as YYYY-MM, YYYY-Q#, or 'all' */
    datePeriod: string
): Promise<boolean> {
    const res = await fetch(
        `${PUBLIC_S3_HOST}/${slug}/data-export/${vehicleClass}/${datePeriod}/trip_metrics.json`,
        { method: 'HEAD' }
    );

    // a 403 or 404 indicates the data doesn't exist
    if (res.status === 403 || res.status === 404) {
        return false;
    }

    // if we get a different error, throw it here.
    if (!res.ok) {
        throw new Error(
            `Could not check data for vehicle class ${vehicleClass} and time range ${datePeriod}. ${res.status}: ${res.statusText}`
        );
    }

    // if there's no error, the file exists.
    return true;
}

export type VehicleClass =
    | 'all'
    | 'bike'
    | 'car'
    | 'e-bike'
    | 'moped'
    | 'scooter'
    | 'seated-scooter'
    | 'cargo-bike'
    | 'cargo-e-bike'
    | 'delivery-robot'
    | 'bus'
    | 'truck'
    | 'motorcycle';

export function isVehicleClass(vc: string): vc is VehicleClass {
    return [
        'all',
        'bike',
        'car',
        'e-bike',
        'moped',
        'scooter',
        'seated-scooter',
        'cargo-bike',
        'cargo-e-bike',
        'delivery-robot',
        'bus',
        'truck',
        'motorcyle',
    ].includes(vc);
}

async function routes(
    slug: string,
    vehicleClass: VehicleClass,
    /** Formatted as YYYY-MM, YYYY-Q#, or 'all' */
    datePeriod: string
): Promise<[RoutesCountData | null, string | null]> {
    let routesData: RoutesCountData | null = null;
    let error: string | null = null;

    const response = await fetch(
        `${PUBLIC_S3_HOST}/${slug}/data-export/${vehicleClass}/${datePeriod}/route_aggregation.geojson`
    );

    if (!response.ok) {
        // if the file doesn't exist in a folder, we may get a 403 from AWS
        if (response.status === 403 || response.status === 404) {
            error =
                'No MDS data for the current selection. Please try a different data period.';
        } else {
            if (window.rollbar) {
                const err = new Error(
                    `Couldn't fetch routes, ${response.status} ${response.statusText}`
                );
                window.rollbar.error(err, response);
            }
            error = 'Error loading routes data. Please try again later.';
        }
    } else {
        const data = await response.json();
        routesData = {
            data,
            dataType: 'count',
        };
    }

    return [routesData, error];
}

async function streetSegments(
    slug: string
): Promise<[StreetSegmentsData | null, string | null]> {
    let error: string | null = null;
    let data: StreetSegmentsData | null = null;

    const response = await fetch(
        `${PUBLIC_S3_HOST}/${slug}/base-data/street_segments.geojson`
    );

    if (!response.ok) {
        if (window.rollbar) {
            const err = new Error(
                `Couldn't fetch routes, ${response.status} ${response.statusText}`
            );
            window.rollbar.error(err, response);
        }
        error = 'Error loading routes data. Please try again later.';
    } else {
        data = await response.json();
    }

    return [data, error];
}

async function highInjuryNetwork(
    slug: string
): Promise<[HighInjuryNetworkData | null, string | null]> {
    let error: string | null = null;
    let data: HighInjuryNetworkData | null = null;

    const response = await fetch(
        `${PUBLIC_S3_HOST}/${slug}/base-data/high_injury_network.json`
    );

    if (response.ok) {
        data = await response.json();
    } else if (response.status !== 404 && response.status !== 403) {
        // sometimes these networks won't exist, so a 404 is to be expected.
        // we don't want to show an error to the user in that case.
        if (window.rollbar) {
            const err = new Error(
                `Couldn't fetch high injury network data, ${response.status} ${response.statusText}`
            );
            window.rollbar.error(err, response);
        }
        error =
            'Error loading high injury network data. Please try again later.';
    }

    return [data, error];
}

async function hasHighInjuryNetwork(slug: string): Promise<boolean> {
    const res = await fetch(
        `${PUBLIC_S3_HOST}/${slug}/base-data/high_injury_network.json`,
        { method: 'HEAD' }
    );

    // a 403 or 404 indicates the data doesn't exist
    if (res.status === 403 || res.status === 404) {
        return false;
    }

    // if we get a different error, throw it here.
    if (!res.ok) {
        throw new Error(
            `Could not check high injury network data for ${slug}. ${res.status} ${res.statusText}`
        );
    }

    // if there's no error, the file exists.
    return true;
}

export class NoDataError extends Error {
    constructor() {
        super(
            'No data for the current selection. Please try a different data period.'
        );
    }
}

async function metrics(
    slug: string,
    vehicleClass: VehicleClass,
    /** Formatted as YYYY-MM, YYYY-Q#, or 'all' */
    datePeriod: string
): Promise<MetricsData> {
    const response = await fetch(
        `${PUBLIC_S3_HOST}/${slug}/data-export/${vehicleClass}/${datePeriod}/trip_metrics.json`
    );

    if (!response.ok) {
        // if the file doesn't exist in a folder, we may get a 403 from AWS
        if (response.status === 403 || response.status === 404) {
            throw new NoDataError();
        } else {
            if (window.rollbar) {
                const err = new Error(
                    `Couldn't fetch metrics, ${response.status} ${response.statusText}`
                );
                window.rollbar.error(err, response);
            }
            throw new Error(
                'Error loading metrics data. Please try again later.'
            );
        }
    }
    const data = await response.json();
    return data;
}

interface ExternalVehicleClassTripCountsDict {
    [time_period: string]: number;
}
export interface ExternalTripCountsDict {
    [vehicle_class: string]: ExternalVehicleClassTripCountsDict;
}

export interface ExternalTripCountsData {
    dataStartDate: string;
    dataEndDate: string;
    banner_message: string;
    total_trips_message: string;
    average_trips_per_day_message: string;
    external_trips_explanation: string;
    tripCounts: ExternalTripCountsDict;
}

async function externalTripCounts(json: JSON): Promise<ExternalTripCountsData> {
    return {
        dataStartDate: json['data_start_date'],
        dataEndDate: json['data_end_date'],
        banner_message: json['banner_message'],
        total_trips_message: json['total_trips_message'],
        average_trips_per_day_message: json['average_trips_per_day_message'],
        external_trips_explanation: json['external_trips_explanation'],
        tripCounts: json['trip_counts'],
    };
}

async function getExternalTripCounts(
    slug: string
): Promise<ExternalTripCountsData> {
    const response = await fetch(
        `${PUBLIC_S3_HOST}/${slug}/external_trip_counts.json`
    );

    if (!response.ok) {
        // if the file doesn't exist in a folder, we may get a 403 from AWS
        if (response.status === 403 || response.status === 404) {
            throw new NoDataError();
        } else {
            if (window.rollbar) {
                const err = new Error(
                    `Couldn't fetch external trip counts, ${response.status} ${response.statusText}`
                );
                window.rollbar.error(err, response);
            }
            throw new Error(
                'Error loading external trip counts data. Please try again later.'
            );
        }
    }
    const json = await response.json();
    const data = externalTripCounts(json);
    return data;
}

interface RegionMetadata {
    name: string;
    slug: string;
}

async function allRegionalSlugs() {
    const environmentQueryParam = process.env.NEXT_PUBLIC_DASHBOARD_ENVIRONMENT
        ? `?environment=${process.env.NEXT_PUBLIC_DASHBOARD_ENVIRONMENT}`
        : '';
    const res = await fetch(
        `${PUBLIC_API_HOST}/all_region_settings${environmentQueryParam}`
    );
    if (!res.ok) {
        throw new Error(
            `Could not fetch all regions: ${res.status} ${res.statusText}`
        );
    }
    const data = await res.json();
    return data as RegionMetadata[];
}

async function publicDashboardSummary(): Promise<PublicDashSummaryImport> {
    const fileName =
        process.env.NEXT_PUBLIC_DASHBOARD_ENVIRONMENT === 'production'
            ? PUBLIC_SUMMARY_PRODUCTION
            : PUBLIC_SUMMARY_STAGING;
    const url = `${PUBLIC_S3_HOST}/${fileName}`;
    const response = await fetch(url);
    const data = await response.json();
    return data;
}

export interface RegionSettings {
    name: string;
    slug: string;
    city_metadata: CityMetadata[];
    boundary: [number, number, number, number];
    organization_logo_url: string;
    organization_url: string;
    organization_contact_email: string;
    population: number;
    dashboard_title: string;
}

export interface CityMetadata {
    name: string;
    slug: string;
    population: number;
}

async function regionSettings(slug: string) {
    const searchParams = new URLSearchParams({ slug: slug });

    if (process.env.NEXT_PUBLIC_DASHBOARD_ENVIRONMENT) {
        searchParams.append(
            'environment',
            process.env.NEXT_PUBLIC_DASHBOARD_ENVIRONMENT
        );
    }

    const res = await fetch(
        `${PUBLIC_API_HOST}/region_settings?` + searchParams
    );
    if (!res.ok) {
        if (res.status === 404) {
            return null;
        }

        throw new Error(
            `Could not fetch settings: ${res.status} ${res.statusText}`
        );
    }

    const data = await res.json();
    return data as RegionSettings;
}

export interface BaseImpactVisualization {
    s3_path: string;
    data: object | null;
    vehicle_classes: VehicleClass[];
    title: string | null;
    description: string | null;
    sort_order: number;
}

export type ImpactVisualizationComparisonType =
    | 'absolute'
    | 'relative'
    | 'slider';

export interface ImpactVisualizationArea {
    name: string;
    geography_json: GeoJSON.FeatureCollection<
        GeoJSON.Polygon | GeoJSON.MultiPolygon
    >;
}

export interface ComparisonHeatmap extends BaseImpactVisualization {
    visualization_type: 'comparison_heatmap';
    area: ImpactVisualizationArea;
    before_start_date: string;
    before_end_date: string;
    after_start_date: string;
    after_end_date: string;
    comparison_type: ImpactVisualizationComparisonType;
}

export interface Rideshed extends BaseImpactVisualization {
    visualization_type: 'ride_shed';
    corridor: string[];
    start_date: string;
    end_date: string;
}

export interface SimpleNumber extends BaseImpactVisualization {
    visualization_type: 'number';
    area: ImpactVisualizationArea;
    start_date: string;
    end_date: string | null;
    number_visualization_type: string;
    display_as_hero?: boolean;
    unit?: string;
}

export interface NumberComparison extends BaseImpactVisualization {
    visualization_type: 'number_comparison';
    area: ImpactVisualizationArea;
    before_start_date: string;
    before_end_date: string;
    after_start_date: string;
    after_end_date: string;
    number_visualization_type: string;
    display_as_hero?: boolean;
    unit?: string;
}

export interface Gallery extends BaseImpactVisualization {
    visualization_type: 'gallery';
    map_first_item: boolean;
    media: GalleryMedia[];
}

export interface GalleryMedia {
    media_type: 'image' | 'video';
    media_link: string;
    caption: string;
    order: number;
    thumbnail_url?: string;
}

export type ImpactVisualizationType =
    | ComparisonHeatmap
    | Rideshed
    | SimpleNumber
    | NumberComparison
    | Gallery;

export interface ImpactSection {
    title: string;
    section_data: string;
    sort_order: number;
    visualizations: ImpactVisualizationType[];
    spotlight_type: string | null;
    partner_logo_url: string | null;
    partner_logo_alt_text: string | null;
    hide_map: boolean;
}

export interface BaseImpact {
    name: string;
    description: string;
    mapview_slug: string;
    public_dashboard_slug: string;
    area_for_display: GeoJSON.Polygon | GeoJSON.MultiPolygon;
    slug: string;
    completed: 'completed' | 'in_progress' | 'not_started';
    end_date: string | null;
    start_date: string;
}

export interface Impact extends BaseImpact {
    sections: ImpactSection[];
}

interface NumberValue {
    value: number | undefined;
    extra: string | undefined;
    unit: string | undefined;
    type: string | undefined;
}

interface NumberComparisonValue {
    before: number;
    after: number;
    percent_change: number;
    extra: string | undefined;
    unit: string | undefined;
    type: string | undefined;
}

export interface NumberData {
    data: NumberValue;
    dataType: 'number';
}

export interface NumberComparisonData {
    data: NumberComparisonValue;
    dataType: 'number_comparison';
}

export type NumberVisualizationData = NumberData | NumberComparisonData;
type NumberDataType = 'number' | 'number_comparison';

async function impact(slug: string) {
    const searchParams = new URLSearchParams({ slug: slug });

    if (process.env.NEXT_PUBLIC_DASHBOARD_ENVIRONMENT) {
        searchParams.append(
            'environment',
            process.env.NEXT_PUBLIC_DASHBOARD_ENVIRONMENT
        );
    }

    const res = await fetch(`${IMPACT_API_HOST}/impact?` + searchParams);
    if (!res.ok) {
        if (res.status === 404) {
            return null;
        }

        throw new Error(
            `Could not fetch impact: ${res.status} ${res.statusText}`
        );
    }

    const data = await res.json();
    return {
        ...data,
        area_for_display: JSON.parse(data.area_for_display),
    } as Impact;
}

async function impacts(slug: string): Promise<BaseImpact[]> {
    const searchParams = new URLSearchParams({ mapview_slug: slug });

    if (process.env.NEXT_PUBLIC_DASHBOARD_ENVIRONMENT) {
        searchParams.append(
            'environment',
            process.env.NEXT_PUBLIC_DASHBOARD_ENVIRONMENT
        );
    }

    const res = await fetch(`${IMPACT_API_HOST}/impacts?` + searchParams);
    if (!res.ok) {
        if (res.status === 404) {
            return [];
        }

        throw new Error(
            `Could not fetch impact: ${res.status} ${res.statusText}`
        );
    }

    const data = await res.json();
    return data.impact_data.map((impact: any) => {
        return {
            ...impact,
            area_for_display: JSON.parse(impact.area_for_display),
        } as BaseImpact[];
    });
}
async function allImpactSlugs() {
    const environmentQueryParam = process.env.NEXT_PUBLIC_DASHBOARD_ENVIRONMENT
        ? `?environment=${process.env.NEXT_PUBLIC_DASHBOARD_ENVIRONMENT}`
        : '';
    const res = await fetch(
        `${IMPACT_API_HOST}/all_impacts${environmentQueryParam}`
    );
    if (!res.ok) {
        throw new Error(
            `Could not fetch all impacts: ${res.status} ${res.statusText}`
        );
    }
    const data = await res.json();
    return data;
}

async function mapVisualizationData(
    visualization: ImpactVisualizationType
): Promise<[RoutesData | null, string | null]> {
    let data: RoutesData | null = null;
    const [visData, error] = await visualizationData(visualization);

    // we need to determine the type of data we're receiving so our map
    // rendering code knows how to properly interpret it
    if (visualization.visualization_type === 'comparison_heatmap') {
        data = {
            data: visData,
            dataType: visualization.comparison_type,
        };
    } else if (visualization.visualization_type === 'ride_shed') {
        // this will enable filtering of ride_shed data on the frontend
        // const filteredVisData = Object.fromEntries(
        //     Object.entries(visData as RoutesCountData['data']).filter(
        //         ([_, value]) => {
        //             return countStringToNumber(value.count) >= 3;
        //         }
        //     )
        // );
        data = {
            data: visData,
            dataType: 'count',
        };
    }

    return [data, error];
}

async function numberVisualizationData(
    visualization: ImpactVisualizationType
): Promise<[NumberVisualizationData, string | null]> {
    const [visData, error] = await visualizationData(visualization);

    const data = {
        data: visData,
        dataType: visualization.visualization_type as NumberDataType,
    };

    return [data, error];
}

async function visualizationData(
    visualization: ImpactVisualizationType
): Promise<[any, string | null]> {
    let error: string | null = null;
    const url = `${IMPACT_S3_HOST}/${visualization.s3_path}`;
    const res = await fetch(url);
    if (!res.ok) {
        error = `Could not fetch impact visualization data for ${visualization.visualization_type}: ${visualization.s3_path}`;
    }

    const data = await res.json();

    return [data, error];
}

export const api = {
    allDashboardSlugs,
    getExternalTripCounts,
    hasHighInjuryNetwork,
    highInjuryNetwork,
    impact,
    impacts,
    isDataAvailableForDatePeriod,
    metrics,
    routes,
    settings,
    streetSegments,
    allRegionalSlugs,
    publicDashboardSummary,
    regionSettings,
    allImpactSlugs,
    numberVisualizationData,
    mapVisualizationData,
    PUBLIC_S3_HOST,
};
